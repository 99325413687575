import axios from "axios";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const UpdateAskToMentorAPI = async (userId, schoolId, userType, year, MessageTomentor, audioValue, dateData, fileName, Subject) => {
  try {
    const key = "webServiceURLAWSDOTNET";
    const returnUrl = await getUserDataFromLocalByKey(userId, schoolId, userType, year, key);
    const SMSType = process.env.REACT_APP_SMS_TYPE;

    const formData = new FormData();
    formData.append("title", "UpdatePM");
    formData.append("description", "");
    formData.append("ReqAcastart", year);
    formData.append("ReqAdmno", userId);
    formData.append("ReqPMDate", dateData);
    formData.append("ReqPMessage", MessageTomentor);
    formData.append("ReqSubject", Subject);
    formData.append("photoLocation", fileName);
    formData.append("imagestring", audioValue);
    formData.append("ReqSMSType", SMSType);

    let userdata = [];
    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
