import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sentOtp } from "../API/AdminOtpAPI";
import { getwebServiceUrlPreRegistration } from "../API/GetWebUrlPreRegister";
import { sentStudentOtp } from "../API/StudentOtpAPI";
import { sentTeacherOtp } from "../API/TeacherOtpAPI";
import { userVerifyAdmin } from "../API/UserVerificationAdmin";
import { userVerifyStudent } from "../API/UserVerificationStudent";
import { userVerifyTeacher } from "../API/UserVerificationTeacher";

const Verification = () => {
  const [otp, setOTP] = useState("");
  const [isResent, setIsResent] = useState(false);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const { userDetails, resultData, webServiceResult } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];
    setRegisteredUsers(users);
  }, []);

  const handleResendOTP = async () => {
    setIsLoading(true);
    const itemKeyName = "webServiceURLAWSDOTNET";
    const preRegisterUrl = await getwebServiceUrlPreRegistration(webServiceResult, itemKeyName);

    try {
      if (userDetails.userType === "Teacher") {
        const res = await sentTeacherOtp(userDetails.userId, userDetails.year, preRegisterUrl);
        if (res) toast.success("OTP Resent Successfully");
      } else if (userDetails.userType === "Student") {
        const res = await sentStudentOtp(userDetails.userId, userDetails.year, preRegisterUrl);
        if (res) toast.success("OTP Resent Successfully");
      } else {
        const res = await sentOtp(userDetails.userId, preRegisterUrl);
        if (res) toast.success("OTP Resent Successfully");
      }
    } catch (error) {
      toast.error("Failed to resend OTP");
    } finally {
      setIsLoading(false);
      setIsResent(true);
    }
  };

  const handleOTpSubmit = async () => {
    if (!otp || otp.length !== 5) {
      toast.error("Please enter a valid 5-digit OTP");
      return;
    }

    setIsLoading(true);
    try {
      const itemKeyName = "webServiceURLAWSDOTNET";
      const preRegisterUrl = await getwebServiceUrlPreRegistration(webServiceResult, itemKeyName);

      let resultantvalue;
      if (userDetails.userType === "Teacher") {
        resultantvalue = await userVerifyTeacher(otp, userDetails, preRegisterUrl);

        if (resultantvalue[0].InfoField1 === "0") {
          const combinedWebNInfo = resultData.concat(webServiceResult);
          const combinedData = [userDetails, combinedWebNInfo];
          const registeredUsers = JSON.parse(localStorage.getItem("registeredUsers"));
          if (registeredUsers) {
            localStorage.setItem("registeredUsers", JSON.stringify([...registeredUsers, combinedData]));
          } else {
            localStorage.setItem("registeredUsers", JSON.stringify([combinedData]));
          }
          toast.success("User Verified Successfully");
          setTimeout(() => navigate("/"), 2000);
        } else {
          toast.error(resultantvalue[0].InfoField);
        }
      } else if (userDetails.userType === "Student") {
        resultantvalue = await userVerifyStudent(otp, userDetails, preRegisterUrl);
        if (resultantvalue[0].InfoField1 === "0") {
          const combinedWebNInfo = resultData.concat(webServiceResult);
          const combinedData = [userDetails, combinedWebNInfo];
          const registeredUsers = JSON.parse(localStorage.getItem("registeredUsers"));
          if (registeredUsers) {
            localStorage.setItem("registeredUsers", JSON.stringify([...registeredUsers, combinedData]));
          } else {
            localStorage.setItem("registeredUsers", JSON.stringify([combinedData]));
          }
          toast.success("User Verified Successfully");
          setTimeout(() => navigate("/"), 2000);
        } else {
          toast.error(resultantvalue[0].InfoField);
        }
      } else {
        resultantvalue = await userVerifyAdmin(otp, userDetails, preRegisterUrl);
        if (resultantvalue[0].InfoField1 === "1") {
          const combinedData = [userDetails, webServiceResult];
          const registeredUsers = JSON.parse(localStorage.getItem("registeredUsers"));
          if (registeredUsers) {
            localStorage.setItem("registeredUsers", JSON.stringify([...registeredUsers, combinedData]));
          } else {
            localStorage.setItem("registeredUsers", JSON.stringify([combinedData]));
          }
          toast.success("User Verified Successfully");
          setTimeout(() => navigate("/"), 2000);
        } else {
          toast.error(resultantvalue[0].InfoField);
        }
      }
    } catch (error) {
      toast.error("Verification failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
      <div className="bg-white rounded-3xl shadow-2xl w-full max-w-md p-8 relative overflow-hidden">
        {/* Background decoration */}
        <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-blue-500 to-indigo-500"></div>

        {/* Header section */}
        <div className="text-center mb-8">
          <div className="w-20 h-20 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
            <svg className="w-10 h-10 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
              />
            </svg>
          </div>
          <h1 className="text-3xl font-bold text-gray-800 mb-2">Verify Your Account</h1>
          <p className="text-gray-600">Please enter the verification code sent to your Number</p>
        </div>

        {/* OTP Input */}
        <div className="mb-8">
          <input
            type="text"
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
            placeholder="Enter 5-digit OTP"
            maxLength="6"
            className="w-full px-4 py-3 text-center text-lg font-semibold tracking-widest 
                                 bg-gray-50 border-2 border-gray-200 rounded-xl outline-none
                                 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 
                                 transition-all duration-300"
          />
        </div>

        {/* Action Buttons */}
        <div className="space-y-4">
          <button
            onClick={handleOTpSubmit}
            disabled={isLoading}
            className={`w-full bg-gradient-to-r from-blue-600 to-indigo-600 
                                 text-white font-semibold py-4 rounded-xl
                                 hover:from-blue-700 hover:to-indigo-700 
                                 focus:ring-2 focus:ring-blue-300 
                                 transition-all duration-300
                                 flex items-center justify-center
                                 ${isLoading ? "opacity-70 cursor-not-allowed" : ""}`}
          >
            {isLoading ? (
              <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
            ) : (
              "Verify Account"
            )}
          </button>

          <button
            onClick={handleResendOTP}
            disabled={isResent || isLoading}
            className={`w-full bg-gray-50 text-gray-700 font-semibold py-4 rounded-xl
                                 border-2 border-gray-200 hover:bg-gray-100
                                 focus:ring-2 focus:ring-gray-200
                                 transition-all duration-300
                                 ${isResent || isLoading ? "opacity-70 cursor-not-allowed" : ""}`}
          >
            {isResent ? "OTP Sent Successfully" : "Resend OTP"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Verification;
