import axios from "axios";

export const GetClassWiseSubjectWiseMarkEntryListCCE = async (year, SvrExmid, Class, section, subject, catId) => {
  try {
    const url = process.env.REACT_APP_ERP_CCE_EXAM_PAGE;

    const formData = new FormData();
    formData.append("title", "GetClassWiseSubjectWiseMarkEntryListCCE");
    formData.append("description", "Get Markentry List");
    formData.append("ReqAcastart", year);
    formData.append("ReqSvrExmid", SvrExmid);
    formData.append("ReqSubject", subject);
    formData.append("ReqClName", Class);
    formData.append("ReqSecName", section);
    formData.append("ReqSeleCatID", catId);
    formData.append("ReqMode", 10);

    let userdata = [];

    const result = await axios.post(url, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
