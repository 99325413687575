import axios from "axios";

export const UpdateClassWiseSubjectWiseMarkEntryCBSE = async (data, stdId, catId) => {
  try {
    const url = process.env.REACT_APP_ERP_CBSE_EXAM_PAGE;
    const formData = new FormData();

    formData.append("title", "UpdateClassWiseSubjectWiseMarkEntryCBSE");
    formData.append("description", "Get Markentry List");
    formData.append("ReqJSonData", JSON.stringify([data]));
    formData.append("ReqStdYrid", stdId);
    formData.append("ReqCatID", catId);
    formData.append("ReqMode", 10);

    let userdata = [];
    const result = await axios.post(url, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
