import axios from "axios";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const getStaffDetailsList = async (userId, schoolId, userType, year) => {
  try {
    const key = "webServiceURL";

    const returnUrl = await getUserDataFromLocalByKey(userId, schoolId, userType, year, key);
    const formData = new FormData();

    formData.append("title", "GetStaffList");
    formData.append("Rdescription", "");
    formData.append("ReqAcastart", year);
    formData.append("ReqCategory", "Teachers");

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
