import React from "react";
import { useParams } from "react-router-dom";
import MarkEntryPageCat7 from "./MarkEntryPageCat7";
import MarkEntryPageCat1 from "./MarkEntryPageCat1";
import MarkEntryPageCat8 from "./MarkEntryPageCat8";

function MarkEntryPage() {
  const { catId } = useParams();
  if (catId === "7") {
    return <MarkEntryPageCat7 />;
  } else if (catId === "8" || catId === "6") {
    return <MarkEntryPageCat8 />;
  } else {
    return <MarkEntryPageCat1 />;
  }
}
export default MarkEntryPage;
