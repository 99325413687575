import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetClassWiseSubjectWiseMarkEntryListCCE } from "../../API/GetClassWiseSubjectWiseMarkEntryListCCE";
import { UpdateClassWiseSubjectWiseMarkEntryCCE } from "../../API/UpdateClassWiseSubjectWiseMarkEntryCCE";
import image from "../../assets/png/avatar.png";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Spinner from "../../components/Spinner";
import { useSidebar } from "../../context/SidebarContext";
import Swal from "sweetalert2";

function MarkEntryPageCat7() {
  const [userDetails, setUserDetails] = useState(null);
  const { customId, Class, section, subject, SvrExmid, catId, examName } = useParams();
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [exam, setExamName] = useState("");

  const { isSidebarOpen, toggleSidebar } = useSidebar();

  const fetchData = async () => {
    if (customId) {
      setLoading(true);
      const decodedData = JSON.parse(atob(customId));
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      const matchedItem = users.flatMap((innerArray) =>
        innerArray.filter(
          (userData) =>
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
        )
      );

      const result = await GetClassWiseSubjectWiseMarkEntryListCCE(decodedData.year, SvrExmid, Class, section, subject, catId);
      const action = result[0]?.ActionType;
      if (action > 0) {
        const js1 = JSON.parse(result[0]?.JSONData1);
        setExamName(js1[0]?.ExamTerm);
        setTableData(js1[0]);
        setGroupList(JSON.parse(js1[0]?.GradeList));
      } else {
        const err = result[0]?.JSONData1;
        toast.warn(err);
      }
      setLoading(false);
      setUserDetails(matchedItem);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customId]);

  const TableHead = tableData?.SubjectList?.map((data, index) => {
    if (data?.Typeid > 10) {
      return {
        Activities: "Grade",
        SVRSubjID: data?.SVRSubjID,
        SvrExmSbjID: data?.SvrExmSbjID,
        SvrExmid: data?.SvrExmid,
        exam: data?.exam,
        MaxMark: data?.MaxMark,
        group: true,
        setGroupList: tableData?.GradeList,
      };
    } else {
      return {
        Activities: data?.Activities,
        SVRSubjID: data?.SVRSubjID,
        SvrExmSbjID: data?.SvrExmSbjID,
        SvrExmid: data?.SvrExmid,
        exam: data?.exam,
        MaxMark: data?.MaxMark,
        group: false,
        setGroupList: "",
      };
    }
  });

  const groupValue = tableData?.SubjectList?.some((data) => {
    console.log(data?.Typeid);
    return data?.Typeid > 10;
  });

  useEffect(() => {
    if (groupValue) setGroup(true);
    else setGroup(false);
  }, [groupValue]);

  const TableBody = tableData?.StudentsMarks?.map((item, index) => ({
    FullName: item.Fname,
    Grade: item.SGrade,
    Rno: item.Rno,
    ATTSTS: item.ATTSTS,
    MXM: item.MXM !== 0 ? item.MXM : null,
    MXM1: item.MXM1 !== 0 ? item.MXM1 : null,
    MXM2: item.MXM2 !== 0 ? item.MXM2 : null,
    OBTM1: item.OBTM1,
    OBTM2: item.OBTM2,
    OBTM3: item.OBTM3,
    OBTM4: item.OBTM4,
    OBTSM: item.OBTSM,
    OBTSMP: item.OBTSMP,
    RCDID1: item.RCDID1,
    Religion: item.Religion,
    STDMSTID: item.STDMSTID,
    Sname: item.Sname,
    adno: item.adno,
    SvrStdExmSbjid: item.SvrStdExmSbjid,
    SvrExmid: item.SvrExmid,
    STDYRID: item.STDYRID,
    SvrExmSbjID: item.SvrExmSbjID,
  }));

  const handleInputChange = (e, index, key, maxMark) => {
    let value = e.target.value;

    if ((isNaN(parseFloat(value)) || parseFloat(value) < 0) && !group) {
      console.warn("Invalid input. Please enter a positive number.");
      return;
    }

    if (parseFloat(value) > maxMark && !group) {
      console.warn("Exceed Max mark");
      toast.warn("Mark cannot exceed " + maxMark);
      value = 0; // Set to max if exceeded
    }

    setTableData((prevState) => {
      const updatedStudentsMarks = prevState.StudentsMarks.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      });

      return {
        ...prevState,
        StudentsMarks: updatedStudentsMarks,
      };
    });
  };

  const handleTableSubmit = async (item, id) => {
    setSubmit(true);

    try {
      const sendData = { ...tableData };
      sendData.StudentsMarks = [item];
      setSubmit(true);
      const response = await UpdateClassWiseSubjectWiseMarkEntryCCE(sendData, id, catId);
      let actionCode = response[0]?.ActionType;
      if (actionCode > 0) {
        toast.success("update success");
        const decodedData = JSON.parse(atob(customId));
        const result = await GetClassWiseSubjectWiseMarkEntryListCCE(decodedData.year, SvrExmid, Class, section, subject, catId);
        const action = result[0]?.ActionType;
        if (action > 0) {
          const js1 = JSON.parse(result[0]?.JSONData1);
          setExamName(js1[0]?.ExamTerm);
          setTableData(js1[0]);
          setGroupList(JSON.parse(js1[0]?.GradeList));
        } else {
          const err = result[0]?.JSONData1;
          toast.warn(err);
        }
      } else {
        setSubmit(false);
        const errCode = response[0]?.ErrorCode;
        const err = response[0]?.ErrorMessage;
        if (errCode === "-100" || errCode === "-500") {
          Swal.fire({
            title: "UnAuthorized",
            text: err,
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "CLOSE",
          }).then(async (result) => {
            if (result.isConfirmed) {
            }
          });
        } else {
          const errorMsg = response[0]?.ErrorMessage ?? "";

          Swal.fire({
            position: "bottom-end",
            icon: "error",
            title: errorMsg,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      setSubmit(false);
    } catch (error) {
      setSubmit(false);
      toast.warn("Something went wrong");
      console.log(error);
    } finally {
      setSubmit(false);
    }
  };

const handleGlobalSave = async () => {
    try {
      const response = await UpdateClassWiseSubjectWiseMarkEntryCCE(tableData, "", catId);
      console.log(response, "response");
      let actionCode = response[0]?.ActionType;
      if (actionCode > 0) {
        toast.success("update success");
        const decodedData = JSON.parse(atob(customId));
        const result = await GetClassWiseSubjectWiseMarkEntryListCCE(decodedData.year, SvrExmid, Class, section, subject, catId);
        const action = result[0]?.ActionType;
        if (action > 0) {
          const js1 = JSON.parse(result[0]?.JSONData1);
          setExamName(js1[0]?.ExamTerm);
          setTableData(js1[0]);
          setGroupList(JSON.parse(js1[0]?.GradeList));
        } else {
          const err = result[0]?.JSONData1;
          toast.warn(err);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Navbar
        data={userDetails}
        // isOpen={sidebarVisible}
        toggleSidebar={toggleSidebar}
      />
      <div className="flex flex-grow">
        {<Sidebar data={userDetails} />}
        <main
          className={`w-full absolute ${
            isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
          } sm:w-11/12 right-0 top-16 mx-auto   md:p-2 lg:p-4 xl:p-6`}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="mt-5 p-2 md:mt-2 lg:p-0 lg:mt-0 border border-black">
                <div
                  onClick={() => setIsVisible(!isVisible)}
                  className="cursor-pointer shadow-lg w-fit absolute top-6 right-6 p-2 rounded-full bg-white border border-gray-300"
                >
                  {isVisible ? <EyeOffIcon className="h-6 w-6 text-gray-700" /> : <EyeIcon className="h-6 w-6 text-gray-700" />}
                </div>
                <h2 className="w-full text-center text-2xl uppercase text-black font-mono font-semibold">
                  Subject wise Mark Entry Exam Category
                </h2>

                <div className={`flex flex-col gap-3 shadow-lg md:px-3 rounded p-3 ${isVisible ? "block" : "hidden"}`}>
                  <div className="flex py-2 formBackground justify-between md:flex-row flex-col px-3 sm:px-5 gap-10 items-center">
                    <div className="w-full  gap-2 md:gap-5 flex justify-between flex-col lg:flex-row">
                      <div className="w-full grid grid-cols-2 gap-4">
                        <div className="flex flex-col">
                          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="class-select">
                            Class
                          </label>
                          <input type="text" className="textInput w-full" value={Class} />
                        </div>

                        <div className="flex flex-col">
                          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="section-select">
                            Section
                          </label>
                          <input type="text" className="textInput w-full" value={section} />
                        </div>

                        <div className="flex flex-col">
                          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="exam-select">
                            Exam
                          </label>
                          <div className="relative">
                            <input type="text" className="textInput w-full" value={examName} />
                          </div>
                        </div>

                        <div className="flex flex-col">
                          <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor="subject-select">
                            Subject
                          </label>
                          <input type="text" className="textInput w-full" value={subject} />
                        </div>
                        <div className="flex mt-1">
                          <input
                            checked={showAll}
                            onChange={(e) => setShowAll(e.target.checked)}
                            id="ShAll"
                            type="checkbox"
                            className="me-2"
                          />
                          <label
                            className="block uppercase tracking-wide text-start text-grey-darker text-xs font-bold mb-2"
                            htmlFor="ShAll"
                          >
                            Show All
                          </label>
                        </div>
                        <div className="flex justify-end items-center">
                          <button
                            onClick={handleGlobalSave}
                            type="button"
                            className="px-3 py-2 rounded-md bg-blue-500 text-white font-medium"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {TableBody && TableHead && (
                  <div className="md:p-2 md:mt-0 mt-3 w-full shadow-lg">
                    <div className="relative max-h-96 overflow-x-auto border md:block hidden border-black border-solid rounded-sm">
                      <table className="w-full bg-white text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="tableHead sticky top-0  blur-0">
                          <tr>
                            <th scope="col" className="p-1 uppercase text-sm">
                              R. No.
                            </th>
                            <th scope="col" className="p-1 uppercase text-sm">
                              Adm. No
                            </th>
                            <th scope="col" className="p-1 uppercase text-sm">
                              Name
                            </th>

                            {TableHead?.map((data, index) => {
                              return (
                                <th key={index} scope="col" className="p-1 uppercase text-sm">
                                  {data?.Activities} <br />{" "}
                                  {!group && <span className="text-sm font-normal font-poppins text-white">({data?.MaxMark})</span>}
                                </th>
                              );
                            })}

                            {!group && (
                              <>
                                <th scope="col" className="p-1 uppercase text-sm">
                                  Total
                                </th>
                                <th scope="col" className="p-1 uppercase text-sm">
                                  Tot%
                                </th>
                                <th scope="col" className="p-1 uppercase text-sm">
                                  Grade
                                </th>
                              </>
                            )}
                            <th scope="col" className="p-1 uppercase text-sm">
                              TCSTS
                            </th>
                            <th scope="col" className="p-1 uppercase text-sm">
                              Save
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData?.StudentsMarks?.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                {showAll ? (
                                  <>
                                    <tr
                                      className={`border-b ${
                                        index % 2 === 0 ? "bg-white" : "bg-slate-200"
                                      }  dark:border-gray-700`}
                                    >
                                      <td className="p-3 border border-solid border-black/10">{item?.Rno}</td>
                                      <td className="p-3 border border-solid border-black/10 font-medium text-gray-900 whitespace-nowrap ">
                                        {item?.adno}
                                      </td>
                                      <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">{item?.Sname}</td>
                                      {TableHead?.map((data, i) => (
                                        <React.Fragment key={i}>
                                          {i !== undefined && i !== null ? (
                                            <>
                                              {item?.ATTSTS === "AB" ? (
                                                <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                  <input
                                                    className="textInput w-20"
                                                    type="text"
                                                    name="ATTSTS"
                                                    onChange={(e) => handleInputChange(e, index, "ATTSTS", "")}
                                                    value={item?.ATTSTS}
                                                  />
                                                </td>
                                              ) : (
                                                <>
                                                  {group ? (
                                                    <>
                                                      <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                        <select
                                                          value={item?.ATTSTS1}
                                                          onChange={(e) => handleInputChange(e, index, "ATTSTS1", data?.MaxMark)}
                                                          name="ATTSTS1"
                                                          id=""
                                                          className="textInput w-20"
                                                        >
                                                          {groupList?.length > 0 &&
                                                            groupList?.map((item, index) => {
                                                              return (
                                                                <option key={index} value={item?.GradeName}>
                                                                  {item?.GradeName}
                                                                </option>
                                                              );
                                                            })}
                                                        </select>
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {item?.[`ATTSTS${i + 1}`] && TableHead.length > i ? (
                                                        <>
                                                          <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                            <input
                                                              onChange={(e) => handleInputChange(e, index, `ATTSTS${i + 1}`, data?.MaxMark)}
                                                              className="textInput w-20"
                                                              type="text"
                                                              name={`ATTSTS${i + 1}`}
                                                              value={item?.[`ATTSTS${i + 1}`]}
                                                            />
                                                          </td>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                            <input
                                                              onChange={(e) => handleInputChange(e, index, `ATTSTS${i + 1}`, data?.MaxMark)}
                                                              className="textInput w-20"
                                                              type="text"
                                                              name={`ATTSTS${i + 1}`}
                                                              value={0}
                                                            />
                                                          </td>
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ) : null}
                                        </React.Fragment>
                                      ))}

                                      {!group && (
                                        <>
                                          {" "}
                                          <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                            {item?.OBTSM}
                                          </td>
                                          <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                            {item?.OBTSMP}
                                          </td>
                                          <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                            {item?.Grade}
                                          </td>
                                        </>
                                      )}

                                      <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">{item?.TCSTS}</td>

                                      <td className="p-3 border border-solid border-black/10 font-medium">
                                        <button
                                          onClick={() => handleTableSubmit(item, item.STDYRID)}
                                          type="button"
                                          disabled={submit}
                                          className="buttonClass !uppercase !text-xs !bg-gradient-to-br !from-pink-600 !via-red-500 !to-rose-600"
                                        >
                                          {submit ? "Saving..." : "Save"}
                                        </button>
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    {item?.TCSTS === "A" && (
                                      <tr
                                        className={`border-b ${
                                          index % 2 === 0 ? "bg-white" : "bg-slate-200"
                                        }  dark:border-gray-700`}
                                      >
                                        <td className="p-3 border border-solid border-black/10">{item?.Rno}</td>
                                        <td className="p-3 border border-solid border-black/10 font-medium text-gray-900 whitespace-nowrap ">
                                          {item?.adno}
                                        </td>
                                        <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">{item?.Sname}</td>
                                        {TableHead?.map((data, i) => (
                                          <React.Fragment key={i}>
                                            {/* {item?.STDYRID === 31502 ? console.log(item) : ""} */}
                                            {i !== undefined && i !== null ? (
                                              <>
                                                {item?.ATTSTS === "AB" ? (
                                                  <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                    <input
                                                      className="textInput w-20"
                                                      type="text"
                                                      onChange={(e) => handleInputChange(e, index, "ATTSTS", "")}
                                                      value={item?.ATTSTS}
                                                      name="ATTSTS"
                                                    />
                                                  </td>
                                                ) : (
                                                  <>
                                                    {group ? (
                                                      <>
                                                        <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                          <select
                                                            name="ATTSTS1"
                                                            value={item?.ATTSTS1}
                                                            onChange={(e) => handleInputChange(e, index, "ATTSTS1", data?.MaxMark)}
                                                            id=""
                                                            className="textInput w-20"
                                                          >
                                                            {groupList?.length > 0 &&
                                                              groupList?.map((item, index) => {
                                                                return (
                                                                  <option key={index} value={item?.GradeName}>
                                                                    {item?.GradeName}
                                                                  </option>
                                                                );
                                                              })}
                                                          </select>
                                                        </td>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {item?.[`ATTSTS${i + 1}`] && TableHead.length > i ? (
                                                          <>
                                                            <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                              <input
                                                                onChange={(e) =>
                                                                  handleInputChange(e, index, `ATTSTS${i + 1}`, data?.MaxMark)
                                                                }
                                                                className="textInput w-20"
                                                                type="text"
                                                                name={`ATTSTS${i + 1}`}
                                                                value={item?.[`ATTSTS${i + 1}`]}
                                                              />
                                                            </td>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                                              <input
                                                                onChange={(e) =>
                                                                  handleInputChange(e, index, `ATTSTS${i + 1}`, data?.MaxMark)
                                                                }
                                                                className="textInput w-20"
                                                                type="text"
                                                                name={`ATTSTS${i + 1}`}
                                                                value={0}
                                                              />
                                                            </td>
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ) : null}
                                          </React.Fragment>
                                        ))}
                                        {!group && (
                                          <>
                                            <td className="p-3 border border-solNofDefStdd border-black/10 font-medium text-gray-800">
                                              {item?.OBTSM}
                                            </td>
                                            <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                              {item?.OBTSMP}
                                            </td>
                                            <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">
                                              {item?.Grade}
                                            </td>
                                          </>
                                        )}
                                        <td className="p-3 border border-solid border-black/10 font-medium text-gray-800">{item?.TCSTS}</td>
                                        <td className="p-3 border border-solid border-black/10 font-medium">
                                          <button
                                            onClick={() => handleTableSubmit(item, item.STDYRID)}
                                            type="button"
                                            disabled={submit}
                                            className="buttonClass !uppercase !text-xs !bg-gradient-to-br !from-pink-600 !via-red-500 !to-rose-600"
                                          >
                                            Save
                                          </button>
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="md:hidden block">
                      <div className="block md:hidden h-screen overflow-auto max-w-2xl mx-auto w-full bg-white shadow-md p-2 rounded-lg">
                        <h2 className="text-lg cardHeadingClass mb-4 w-full text-center">Students List</h2>
                        <div className="overflow-x-auto">
                          {tableData?.StudentsMarks?.map((item, index) => {
                            return (
                              <>
                                {showAll ? (
                                  <div
                                    className="border-2 bg-gradient-to-b from-sky-200 to-cyan-400 rounded-lg mb-4 shadow-lg overflow-hidden"
                                    key={index}
                                  >
                                    <div className="flex students-center justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                                      <input className="radioButton" type="radio" name="employeesRadio" id={`employeesRadio-${index}`} />
                                      <span className="font-bold text-center w-full"> {item?.Sname}</span>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                      <img src={image} alt="Student" className=" h-28 w-28 object-cover p-4 rounded-full" />
                                    </div>
                                    <table className="w-full table-auto mt-2">
                                      <tbody>
                                        <tr>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            <strong>Adm. No</strong>
                                          </td>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.adno}</td>
                                        </tr>
                                        {TableHead?.map((data, ind) => (
                                          <tr key={ind}>
                                            <React.Fragment>
                                              {ind !== undefined && ind !== null ? (
                                                <>
                                                  {item?.ATTSTS === "AB" ? (
                                                    <>
                                                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                        <strong>{data?.Activities}</strong>
                                                      </td>
                                                      <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                        <input
                                                          className="textInput w-20"
                                                          type="text"
                                                          value={item?.ATTSTS}
                                                          onChange={(e) => handleInputChange(e, index, `ATTSTS`)}
                                                        />
                                                      </td>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {group ? (
                                                        <>
                                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                            <strong>{data?.Activities}</strong>
                                                          </td>
                                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                            <select
                                                              value={item?.ATTSTS1}
                                                              onChange={(e) => handleInputChange(e, index, "ATTSTS1", data?.MaxMark)}
                                                              name=""
                                                              id=""
                                                              className="textInput w-20"
                                                            >
                                                              {groupList?.length > 0 &&
                                                                groupList?.map((item, index) => {
                                                                  return (
                                                                    <option key={index} value={item?.GradeName}>
                                                                      {item?.GradeName}
                                                                    </option>
                                                                  );
                                                                })}
                                                            </select>
                                                          </td>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {item?.[`ATTSTS${ind + 1}`] && TableHead.length > ind ? (
                                                            <>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <strong>{data?.Activities}</strong>
                                                              </td>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <input
                                                                  className="textInput w-20"
                                                                  type="text"
                                                                  value={item?.[`ATTSTS${ind + 1}`]}
                                                                  onChange={(e) =>
                                                                    handleInputChange(e, index, `ATTSTS${ind + 1}`, data?.MaxMark)
                                                                  }
                                                                />
                                                              </td>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <strong>{data?.Activities}</strong>
                                                              </td>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <input
                                                                  className="textInput w-20"
                                                                  type="text"
                                                                  value={0}
                                                                  onChange={(e) =>
                                                                    handleInputChange(e, index, `ATTSTS${ind + 1}`, data?.MaxMark)
                                                                  }
                                                                />
                                                              </td>
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              ) : null}
                                            </React.Fragment>
                                          </tr>
                                        ))}
                                        <tr>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            <strong>Total</strong>
                                          </td>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.OBTSM}</td>
                                        </tr>
                                        <tr>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            <strong>Tot%</strong>
                                          </td>
                                          <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                            {item?.OBTSMP}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            <strong> Grade</strong>
                                          </td>
                                          <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                            {item?.Grade}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                            <strong> TCSTS</strong>
                                          </td>
                                          <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                            {item?.TCSTS}
                                          </td>
                                        </tr>
                                        <tr>
                                          <button
                                            onClick={() => handleTableSubmit(item, item.STDYRID)}
                                            type="button"
                                            disabled={submit}
                                            className="buttonClass !uppercase !text-xs !bg-gradient-to-br !from-pink-600 !via-red-500 !to-rose-600"
                                          >
                                            Save
                                          </button>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  <>
                                    {item?.TCSTS === "A" && (
                                      <div
                                        className="border-2 bg-gradient-to-b from-sky-200 to-cyan-400 rounded-lg mb-4 shadow-lg overflow-hidden"
                                        key={index}
                                      >
                                        <div className="flex students-center justify-between tabButton py-2 px-4 rounded-t-lg shadow-md">
                                          <input
                                            className="radioButton"
                                            type="radio"
                                            name="employeesRadio"
                                            id={`employeesRadio-${index}`}
                                          />
                                          <span className="font-bold text-center w-full"> {item?.Sname}</span>
                                        </div>
                                        <div className="flex justify-center mt-4">
                                          <img src={image} alt="Student" className=" h-28 w-28 object-cover p-4 rounded-full" />
                                        </div>
                                        <table className="w-full table-auto mt-2">
                                          <tbody>
                                            <tr>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                <strong>Adm. No</strong>
                                              </td>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.adno}</td>
                                            </tr>
                                            {TableHead?.map((data, ind) => (
                                              <tr key={ind}>
                                                <React.Fragment>
                                                  {ind !== undefined && ind !== null ? (
                                                    <>
                                                      {item?.ATTSTS === "AB" ? (
                                                        <>
                                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                            <strong>{data?.Activities}</strong>
                                                          </td>
                                                          <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                            <input
                                                              className="textInput w-20"
                                                              type="text"
                                                              value={item?.ATTSTS}
                                                              onChange={(e) => handleInputChange(e, index, `ATTSTS`)}
                                                            />
                                                          </td>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {group ? (
                                                            <>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <strong>{data?.Activities}</strong>
                                                              </td>
                                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                <select
                                                                  value={item?.ATTSTS1}
                                                                  onChange={(e) => handleInputChange(e, index, "ATTSTS1", data?.MaxMark)}
                                                                  name=""
                                                                  id=""
                                                                  className="textInput w-20"
                                                                >
                                                                  {groupList?.length > 0 &&
                                                                    groupList?.map((item, index) => {
                                                                      return (
                                                                        <option key={index} value={item?.GradeName}>
                                                                          {item?.GradeName}
                                                                        </option>
                                                                      );
                                                                    })}
                                                                </select>
                                                              </td>
                                                            </>
                                                          ) : (
                                                            <>
                                                              {item?.[`ATTSTS${ind + 1}`] && TableHead.length > ind ? (
                                                                <>
                                                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                    <strong>{data?.Activities}</strong>
                                                                  </td>
                                                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                    <input
                                                                      className="textInput w-20"
                                                                      type="text"
                                                                      value={item?.[`ATTSTS${ind + 1}`]}
                                                                      onChange={(e) =>
                                                                        handleInputChange(e, index, `ATTSTS${ind + 1}`, data?.MaxMark)
                                                                      }
                                                                    />
                                                                  </td>
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                    <strong>{data?.Activities}</strong>
                                                                  </td>
                                                                  <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                                    <input
                                                                      className="textInput w-20"
                                                                      type="text"
                                                                      value={0}
                                                                      onChange={(e) =>
                                                                        handleInputChange(e, index, `ATTSTS${ind + 1}`, data?.MaxMark)
                                                                      }
                                                                    />
                                                                  </td>
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  ) : null}
                                                </React.Fragment>
                                              </tr>
                                            ))}
                                            <tr>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                <strong>Total</strong>
                                              </td>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">{item?.OBTSM}</td>
                                            </tr>
                                            <tr>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                <strong>Tot%</strong>
                                              </td>
                                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                                {item?.OBTSMP}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                <strong> Grade</strong>
                                              </td>
                                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                                {item?.Grade}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="border border-solid border-gray-400 text-start p-2 w-1/2">
                                                <strong> TCSTS</strong>
                                              </td>
                                              <td className="border border-solid border-gray-400 text-start capitalize p-2 w-1/2">
                                                {item?.TCSTS}
                                              </td>
                                            </tr>
                                            <tr>
                                              <button
                                                onClick={() => handleTableSubmit(item, item.STDYRID)}
                                                type="button"
                                                disabled={submit}
                                                className="buttonClass !uppercase !text-xs !bg-gradient-to-br !from-pink-600 !via-red-500 !to-rose-600"
                                              >
                                                Save
                                              </button>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </main>
      </div>
    </div>
  );
}

export default MarkEntryPageCat7;
