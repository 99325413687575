import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GetTeacherWiseSubjectList } from "../../API/GetTeacherWiseSubjectList";
import Navbar from "../../components/Navbar";
import Spinner from "../../components/Spinner";

function SubjectWiseMarkEntry() {
  const [examList, setExamList] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const { customId, className } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

        const matchedItem = users.flatMap((innerArray) =>
          innerArray.filter(
            (userData) =>
              userData.userId === decodedData.userId &&
              userData.userType === decodedData.userType &&
              userData.schoolId === decodedData.schoolId &&
              userData.year === decodedData.year
          )
        );
        const result = await GetTeacherWiseSubjectList(decodedData.userId, decodedData.year);

        setExamList(result);
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar data={userDetails} />

      <main className="container mx-auto px-4 py-8">
        <header className="mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Admin: {userDetails && userDetails[0]?.schoolId}</h1>
          <h2 className="text-xl font-semibold text-gray-600 mt-2">Class Section List for {className}</h2>
        </header>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {examList?.map((item, index) => {
            return (
              <Link
                key={index}
                to={`/classteacher/u/${customId}/${item?.Subject}/${item?.Class}/${item?.Section}/ClassTeacherExamSelect`}
                className="transform transition duration-300 hover:scale-105"
              >
                <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl">
                  <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4">
                    <h3 className="text-xl font-bold text-white text-center truncate">{item.Subject}</h3>
                  </div>
                  <div className="p-4">
                    <p className="text-gray-600 text-center">
                      {item?.Class}-{item?.Section}
                    </p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </main>
    </div>
  );
}

export default SubjectWiseMarkEntry;
