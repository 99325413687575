export const getUserInfoKey = async (userId, schoolId, userType, year, key) => {
  try {
    const registeredUsersList = JSON.parse(localStorage.getItem("registeredUsers")) || [];
    let matchedItems = [];

    registeredUsersList.forEach((innerArray) => {
      const [userData, innerData] = innerArray;

      if (userData.userId === userId && userData.year === year && userData.schoolId === schoolId && userData.userType === userType) {
        innerData?.forEach((innerItem) => {
          if (innerItem?.ItemKeyName === key) {
            matchedItems.push(innerItem);
          }
        });
      }
    });

    return matchedItems.length > 0 ? matchedItems[0].InfoField : null;
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
};
