import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSidebar } from "../../context/SidebarContext";
import Spinner from "../../components/Spinner";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { GetClassWiseTermList } from "../../API/GetClassWiseTermList";
import { toast } from "react-toastify";

function CTExamSelect() {
  const [examList, setExamList] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const { customId, subject, Class, section } = useParams();
  const [loading, setLoading] = useState(true);

  const { isSidebarOpen, toggleSidebar } = useSidebar();

  useEffect(() => {
    const fetchData = async () => {
      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

        const matchedItem = users.flatMap((innerArray) =>
          innerArray.filter(
            (userData) =>
              userData.userId === decodedData.userId &&
              userData.userType === decodedData.userType &&
              userData.schoolId === decodedData.schoolId &&
              userData.year === decodedData.year
          )
        );

        const result = await GetClassWiseTermList(decodedData.userId, decodedData.year, Class, section, subject);
        const JSONData1 = JSON.parse(result[0]?.JSONData1);
        console.log(JSONData1, "result");
        if (JSONData1?.length > 0) {
          setExamList(JSONData1);
        } else {
          toast.warn("NO EXAM FOUND");
          setExamList([]);
        }
        setLoading(false);
        setUserDetails(matchedItem);
      }
    };

    fetchData();
  }, [customId]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Navbar data={userDetails} toggleSidebar={toggleSidebar} />
      <div className="flex flex-grow">
        {<Sidebar data={userDetails} />}
        <main
          className={`w-full absolute ${
            isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
          } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
        >
          <div className="max-w-7xl mx-auto">
            <header className="mb-8 text-center">
              <h1 className="text-2xl font-bold text-gray-800 mb-2">Admin: {userDetails && userDetails[0]?.schoolId}</h1>
              <h2 className="text-2xl font-bold text-gray-600">Exams of {subject}</h2>
            </header>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {examList?.map((item, index) => (
                <Link
                  key={index}
                  to={`/classteacher/u/${customId}/${subject}/${item?.Svrexmid}/${item?.exam}/${Class}/${section}/${item?.ExamCat}/ClassTeacherMarkEntry`}
                  className="transform transition duration-300 hover:scale-105"
                >
                  <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl">
                    <div className="bg-gradient-to-r from-blue-500 to-indigo-600 p-4">
                      <h3 className="text-xl font-bold text-white text-center truncate">{item.exam}</h3>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default CTExamSelect;
