import axios from "axios";
import { getUserDataFromLocalByKey } from "./getStudentInfoByKeyLocal";

export const updateCTCPollSubmitAPI = async (
  userId,
  schoolId,
  userType,
  year,
  chosenValue,
  pollTitle,
  pollQuestion,
  noOfOptions,
  option1,
  option2,
  option3,
  option4,
  pollLocked,
  pollOver,
  pollDropped,
  rcdid
) => {
  try {
    const key = "webServiceURLAWSDOTNET";
    const classKey = "EMPCLASS";

    const returnUrl = await getUserDataFromLocalByKey(userId, schoolId, userType, year, key);
    const className = await getUserDataFromLocalByKey(userId, schoolId, userType, year, classKey);
    const [classNumber, classSection] = className.split("-");

    const formData = new FormData();
    formData.append("title", "UpdatePoll");
    formData.append("ReqPollID", rcdid || "");
    formData.append("ReqAcastart", year);
    formData.append("ReqPollTitle", pollTitle);
    formData.append("ReqPollQuestion", pollQuestion);
    formData.append("ReqPollNofOpt", noOfOptions);
    formData.append("ReqPollOpt1", option1);
    formData.append("ReqPollOpt2", option2);
    formData.append("ReqPollOpt3", option3);
    formData.append("ReqPollOpt4", option4);
    formData.append("ReqPollLocked", pollLocked);
    formData.append("ReqPollOver", pollOver);
    formData.append("ReqPollDropped", pollDropped);
    formData.append("ReqPollSubjTo", `${classNumber}-${classSection}`);

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${returnUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
