import axios from "axios";

export const UpdateClassWiseSubjectWiseMarkEntryCCE = async (data, stdId, catId) => {
  try {
    const url = process.env.REACT_APP_ERP_CCE_EXAM_PAGE;
    const formData = new FormData();

    formData.append("title", "UpdateClassWiseSubjectWiseMarkEntryCCE");
    formData.append("description", "Get Markentry List");
    formData.append("ReqJSonData", JSON.stringify([data]));
    formData.append("ReqStdYrid", stdId);
    formData.append("ReqCatID", catId);
    formData.append("ReqMode", 10);

    console.log(JSON.stringify([data]), "formData");

    let userdata = [];
    const result = await axios.post(url, formData);
    console.log(result.data, "result");
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
