import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { adminGetTimeTable } from "../API/adminGetTimeTable.jsx";
import { adminRecreateTimeTable } from "../API/adminRecreateTimeTable.jsx";
import { getUserDataFromLocalByKey } from "../API/getStudentInfoByKeyLocal.jsx";
import { userDetailsDataValues } from "../data/adminMenu";

function Modal({ showModal, setShowModal, data, title, path, customId, fun }) {
  const [initialResourceUrl, setInitialResourceUrl] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

      if (customId) {
        const decodedData = JSON.parse(atob(customId));
        let matchedItem = [];

        users.forEach((innerArray) => {
          const [userData] = innerArray;

          if (
            userData.userId === decodedData.userId &&
            userData.userType === decodedData.userType &&
            userData.schoolId === decodedData.schoolId &&
            userData.year === decodedData.year
          ) {
            matchedItem.push(userData);
          }
        });

        setYear(decodedData.year);
        const keyval = "webServiceURLAWSDOTNET";
        const dataUrl = await getUserDataFromLocalByKey(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          keyval
        );
        setInitialResourceUrl(dataUrl);
      }
    };

    fetchData();
  }, []);

  const handleSync = (syncFunc) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will synchronize data. Are you sure you want to proceed?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, sync it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Syncing Data...",
          html: "Please wait while data is being synchronized.",
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
            // Call your synchronization function
            syncFunc(year, initialResourceUrl)
              .then((data) => {
                if (data?.data?.status === true) {
                  Swal.fire({
                    icon: "success",
                    title: "Sync Complete!",
                    text: "Data has been synchronized successfully.",
                    customClass: {
                      confirmButton: "swal-button swal-button--confirm", // Applying base SweetAlert2 class
                    },
                    didOpen: () => {
                      const confirmButton = Swal.getConfirmButton();
                      if (confirmButton) {
                        confirmButton.style.backgroundColor = "#4CAF50"; // Set your desired background color
                      }
                    },
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Sync Failed!",
                    text: `An error occurred`,
                    confirmButtonText: "OK",
                  });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Sync Failed!",
                  text: `An error occurred: ${error.message}`,
                });
              });
          },
        });
      }
    });
  };
  const handleClear = (syncFunc) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will Clear PTAL data. Are you sure you want to proceed?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, clear it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Clearing Data...",
          html: "Please wait while data is being cleared.",
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
            // Call your synchronization function
            syncFunc(year, initialResourceUrl)
              .then((data) => {
                if (data?.data?.status === true) {
                  Swal.fire({
                    icon: "success",
                    title: "Clear Complete!",
                    text: "Data has been cleared successfully.",
                    customClass: {
                      confirmButton: "swal-button swal-button--confirm", // Applying base SweetAlert2 class
                    },
                    didOpen: () => {
                      const confirmButton = Swal.getConfirmButton();
                      if (confirmButton) {
                        confirmButton.style.backgroundColor = "#4CAF50"; // Set your desired background color
                      }
                    },
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Sync Failed!",
                    text: `An error occurred`,
                    confirmButtonText: "OK",
                  });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Sync Failed!",
                  text: `An error occurred: ${error.message}`,
                });
              });
          },
        });
      }
    });
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-black bg-opacity-50 transition-opacity duration-300">
            <div className="relative w-full max-w-3xl mx-auto my-6">
              <div className="rounded-lg shadow-lg flex flex-col w-full bg-white">
                <div className="flex items-start justify-between p-5 border-b border-gray-200 rounded-t bg-gradient-to-r from-purple-500 to-blue-500 text-white">
                  <h3 className="text-3xl font-semibold">{title}</h3>
                  <button
                    className="ml-auto text-white bg-transparent border-0 text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <GrClose size={25} />
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex flex-wrap gap-4 justify-center">
                  {data?.map((items, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        if (items?.onclick) {
                          if (items?.keyName === "clearTable") {
                            handleClear(items?.onclick);
                          } else {
                            handleSync(items?.onclick);
                          }
                        }
                      }}
                      className="w-32 h-32 sm:w-40 sm:h-40 lg:w-48 lg:h-48 flex flex-col items-center justify-evenly bg-gradient-to-r from-indigo-300 to-purple-300 hover:from-indigo-400 hover:to-purple-400 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 p-4 cursor-pointer"
                    >
                      <img src={items?.icon} className="w-12 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20 mb-2" alt="icon" />
                      <div className="text-center">
                        <span className="text-xs sm:text-sm lg:text-base font-medium text-white">{items?.caption}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

const Content = ({ data }) => {
  const [userDetails, setUserDetails] = useState(null);
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [resultArray, setResultArray] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [modalPath, setModalPath] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);

  const { customId } = useParams();

  const ModalContent = ({ data, title, path }) => {
    setModalTitle(title);
    setModalData(data);
    setModalPath(path);
    setShowModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (customId) {
        const decodedData = JSON.parse(atob(customId));

        const result = await userDetailsDataValues(decodedData);
        setResultArray(result);
      }
    };
    fetchData();
  }, [customId]);

  useEffect(() => {
    const decodedData = JSON.parse(atob(customId));

    setUserDetails(decodedData);
  }, [customId]);

  const handleAlert = () => {
    const confirmed = window.confirm("Do you want to re-create?");
    if (confirmed) {
      reCreate();
    }
  };

  const reCreate = async () => {
    const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];
    if (customId) {
      const decodedData = JSON.parse(atob(customId));
      let matchedItem = [];

      users.forEach((innerArray) => {
        const [userData] = innerArray;

        if (
          userData.userId === decodedData.userId &&
          userData.userType === decodedData.userType &&
          userData.schoolId === decodedData.schoolId &&
          userData.year === decodedData.year
        ) {
          matchedItem.push(userData);
        }
      });

      const result = await adminGetTimeTable(decodedData.userId, decodedData.schoolId, decodedData.userType, decodedData.year);
      const { JSONData1, JSONData2, JSONData3, JSONData4, JSONData5 } = result[0];

      if (result) {
        const res = await adminRecreateTimeTable(
          decodedData.userId,
          decodedData.schoolId,
          decodedData.userType,
          decodedData.year,
          JSONData1,
          JSONData2,
          JSONData3,
          JSONData4,
          JSONData5
        );

        if (res.status === "success") {
          toast.success("Re-create action performed");
        }
      }

    }
  };

  return (
    <>
      {showModal && (
        <Modal showModal={showModal} setShowModal={setShowModal} title={modalTitle} data={modalData} path={modalPath} customId={customId} />
      )}
      {
        <div className="w-full mx-auto p-4">
          <div className="p-2 mb-3 flex items-center w-fit flex-wrap">
            <ul className="flex items-center">
              <li className="inline-flex items-center cursor-default">
                <a href="/" className="text-black font-medium hover:scale-110">
                  <svg
                    className="w-5 cursor-default h-auto fill-current mx-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#000000"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                  </svg>
                </a>
                <span className="mx-4 h-auto text-gray-400 font-medium">/</span>
              </li>
            </ul>
          </div>

          <div className="container mx-auto p-4 containerBoxShadow">
            {userDetails && userDetails.userType === "Student" && (
              <div className="gridContainer px-2 py-3 grid gap-8 md:gap-5 lg:grid-cols-4 sm:grid-cols-3 md:grid-cols-3 grid-cols-2 place-items-center">
                {resultArray &&
                  resultArray.map((item, index) => (
                    <Link
                      key={index}
                      to={`/student/u/${item.keyName}/${customId}`}
                      className="xl:h-48 xl:w-52 h-36 w-40 hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 rounded-md flex flex-col justify-center items-center bg-white shadow-md"
                    >
                      <img src={item.icon} className="h-20 w-20" alt="" />

                      <div>
                        <h3 className="font-semibold text-md md:text-xl">{item.caption}</h3>
                      </div>
                    </Link>
                  ))}
              </div>
            )}

            {userDetails &&
              userDetails.userType === "Teacher" &&
              (userDetails.thType === "CT" ? (
                <div className="gridContainer px-2 py-3 grid gap-8 md:gap-5 md:grid-cols-4 sm:grid-cols-3 md:grid-cols grid-cols-2 place-items-center">
                  {resultArray &&
                    resultArray.map((item, index) => (
                      <Link
                        key={index}
                        to={`/classteacher/u/${item.keyName}/${customId}`}
                        className="md:h-48 md:w-52 h-36 w-40 hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 rounded-md flex flex-col justify-center items-center bg-white shadow-md"
                      >
                        <img src={item.icon} className="h-20 w-20" alt="" />
                        <div>
                          <h3 className="font-semibold text-md md:text-xl">{item.caption}</h3>
                        </div>
                      </Link>
                    ))}
                </div>
              ) : (
                <div className="gridContainer px-2 py-3 grid gap-8 md:gap-5 md:grid-cols-4 sm:grid-cols-3 md:grid-cols grid-cols-2 place-items-center">
                  {resultArray &&
                    resultArray.map((item, index) => (
                      <Link
                        key={index}
                        to={`/subjectTeacher/u/${item.keyName}/${customId}`}
                        className="md:h-48 md:w-52 h-36 w-40 hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 rounded-md flex flex-col justify-center items-center bg-white shadow-md"
                      >
                        <img src={item.icon} className="h-20 w-20" alt="" />
                        <div>
                          <h3 className="font-semibold text-md md:text-xl">{item.caption}</h3>
                        </div>
                      </Link>
                    ))}
                </div>
              ))}

            {userDetails && userDetails.userType === "Admin" && (
              <div className="gridContainer px-2 py-3 grid gap-8 md:gap-5 xl:grid-cols-4 sm:grid-cols-3 md:grid-cols grid-cols-2 place-items-center">
                {resultArray &&
                  resultArray.map((item) =>
                    item?.keyName === "Sync Data" || item?.keyName === "clearTables" ? (
                      <div
                        onClick={() =>
                          ModalContent({
                            data: item?.modalContent,
                            title: item?.caption,
                            path: item?.redirect,
                          })
                        }
                        className="md:h-48 md:w-52 h-36 w-40 hover:bg-sky-100 transition duration-300 ease-in-out transform hover:scale-105 rounded-md flex flex-col justify-center items-center bg-white shadow-md"
                      >
                        {" "}
                        <img src={item.icon} className="h-20 w-20 " alt="" />
                        <div>
                          <h3 className="font-semibold text-md md:text-xl">{item.caption}</h3>
                        </div>
                      </div>
                    ) : (
                      <Link
                        to={`/admin/u/${item.keyName}/${customId}`}
                        className="md:h-48 md:w-52 h-36 w-40 hover:bg-sky-100 transition duration-300 ease-in-out transform hover:scale-105 rounded-md flex flex-col justify-center items-center bg-white shadow-md"
                      >
                        <img src={item.icon} className="h-20 w-20 " alt="" />
                        <div>
                          <h3 className="font-semibold text-md md:text-xl">{item.caption}</h3>
                        </div>
                      </Link>
                    )
                  )}

                {/* <div
                  onClick={handleAlert}
                  className="md:h-48 md:w-52 h-36 w-40 hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105 rounded-md flex flex-col justify-center items-center bg-white shadow-md hover:cursor-pointer"
                >
                  <img src={calendar2} className="h-20 w-20" alt="" />
                  <div>
                    <h3 className="font-semibold text-md md:text-xl">
                      Recreate Time Table
                    </h3>
                  </div>
                </div> */}
              </div>
            )}
          </div>
        </div>
      }
    </>
  );
};

export default Content;
