import axios from "axios";

export const adminSyncEmployees = async (year, preUrl) => {
  try {
    const postData = {
      year: year,
    };

    const result = await axios.post(`${preUrl}employeeUpdate`, postData, {
      headers: {
        "Content-Type": "application/json", // Specify content type as JSON
      },
    });

    return result;
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle error here
  }
  // Placeholder JSX, replace with actual JSX
};
