import axios from "axios";

export const registerAdminUsers = async (userId, year, preRegisterUrl) => {
  try {
    const formData = new FormData();
    formData.append("title", "GetAdminUserDetails");
    formData.append("ReqUserID", userId);
    formData.append("ReqAcastart", year);
    formData.append("description", "registerChecking");
    formData.append("ReqPhoneCode", "munavir ck");

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${preRegisterUrl}`, formData);

    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
