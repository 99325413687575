import axios from "axios";

export const userVerifyAdmin = async (otpval, userDetails, preRegisterUrl) => {
  try {
    const formData = new FormData();

    formData.append("title", "UserVerificationAdmin");
    formData.append("ReqUserID", userDetails.userId);
    formData.append("ReqVeriCode", otpval);
    formData.append("ReqAcastart", userDetails.year);
    formData.append("ReqPhoneCode", "---");
    formData.append("ReqSMSType", "Testing");
    formData.append("ReqNewPass", "");

    let userdata = [];

    const Corskey = process.env.REACT_APP_CORS_URL;
    const result = await axios.post(`${Corskey}${preRegisterUrl}`, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
