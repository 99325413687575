import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { sentOtp } from "../API/AdminOtpAPI";
import { getCentralServiceDetails } from "../API/GetWebUrlAPI";
import { getwebServiceUrlPreRegistration } from "../API/GetWebUrlPreRegister";
import { registerAdminUsers } from "../API/RegisterAdminUser";
import { registerStudentUsers } from "../API/RegisterStudent";
import { registerTeacherUsers } from "../API/RegisterTeacher";
import { sentStudentOtp } from "../API/StudentOtpAPI";
import { sentTeacherOtp } from "../API/TeacherOtpAPI";
import StudentImage from "../assets/png/studentUsers.jpg";

const NewRegistration = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const navigate = useNavigate();
  const [userType, setUserType] = useState("");
  const [schoolId, setSchoolId] = useState(process.env.REACT_APP_SCHOOL_CODE);
  const [userId, setUserId] = useState("");
  const [year, setYear] = useState("");
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const today = new Date();
    const formattedToday = formatDate(today);
    setSelectedToDate(today);
  }, []);

  //console.log(categoryList);

  const formatDate = (date) => {
    if (!date) return ""; // Handle null or undefined dates

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Ensure leading zeros for day and month
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  };

  const handleCheckboxChange = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (userId === "") {
      toast.error("Please Enter UserId");
    } else if (schoolId.length < 0) {
      toast.error("Please Enter the School Id");
    } else if (userType === "") {
      toast.error("Please Enter UserType");
    } else if (year === "") {
      toast.error("Please Enter Year");
    } else {
      const newUser = {
        userType,
        schoolId,
        userId,
        year,
        thType: "",
      };

      if (!termsAccepted) {
        toast.error("Please accept the terms and conditions");
        return;
      }
      setLoading(true);
      const webService = await getCentralServiceDetails(schoolId);
      console.log(" Central WebService response ", webService);
      console.log(userType, " user tpye");

      const itemKeyName = "webServiceURLAWSDOTNET";

      const preRegisterUrl = await getwebServiceUrlPreRegistration(webService, itemKeyName);
      console.log("PreRegistration URL", preRegisterUrl);

      if (userType === "Admin") {
        const res = await registerAdminUsers(userId.trim(), year, preRegisterUrl);
        console.log("This is the result returned", res[0]);

        if (res[0].InfoField1 === "1") {
          const result = await sentOtp(userId.trim(), preRegisterUrl);
          console.log("This is the result obtained after verificat", result);

          if (result[0].InfoField2) {
            const val = result[0].InfoField2;
            const otpVal = parseInt(val.replace(/\D/g, ""), 10);

            if (otpVal) {
              navigate("/verify", {
                state: { userDetails: newUser, webServiceResult: webService },
              });
            }
          }
        } else {
          alert("Registration Error, Login is Yet To Create", res[0].InfoField);
        }
      } else if (userType === "Teacher") {
        const res = await registerTeacherUsers(userId.trim(), year, preRegisterUrl);

        console.log("This is the return response from Teacher", res);

        if (res[0].InfoField1 === "1" || res[0].InfoField1 === "0") {
          const result = await sentTeacherOtp(userId.trim(), year, preRegisterUrl);

          console.log(result);

          if (result[0].InfoField2) {
            const otpValue = result[0].InfoField2;

            if (otpValue) {
              navigate("/verify", {
                state: {
                  userDetails: newUser,
                  resultData: res,
                  webServiceResult: webService,
                },
              });
            }
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Registration Failed",
            text: res[0]?.InfoField,
            confirmButton: "ok",

            didOpen: () => {
              const confirmButton = Swal.getConfirmButton();
              if (confirmButton) {
                confirmButton.style.backgroundColor = "#af4c4c"; // Set your desired background color
              }
            },
          });
          toast.error(res[0]?.InfoField);
        }
      } else if (userType === "Student") {
        const res = await registerStudentUsers(userId.trim(), year, preRegisterUrl);

        console.log(res, "response in register");

        if (res[0].InfoField1 === "0") {
          const result = await sentStudentOtp(userId.trim(), year, preRegisterUrl);

          console.log("This is the resutlt obtained calling student  otp", result);

          if (result[0].InfoField2) {
            const otpValue = result[0].InfoField2;

            if (otpValue) {
              navigate("/verify", {
                state: {
                  userDetails: newUser,
                  resultData: res,
                  webServiceResult: webService,
                },
              });
            }
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Registration Failed",
            text: res[0]?.InfoField,
            confirmButton: "ok",

            didOpen: () => {
              const confirmButton = Swal.getConfirmButton();
              if (confirmButton) {
                confirmButton.style.backgroundColor = "#af4c4c"; // Set your desired background color
              }
            },
          });

          toast.error(res[0]?.InfoField);
        }
      } else {
        // const res = await registerStudents(userType, userId, year);
        alert("Login Failed");
      }

      // Clear the form fields after submission
      setUserType("");
      setSchoolId("");
      setUserId("");
      setYear("");
      setLoading(false);
    }
  };

  return (
    <div className="h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 to-indigo-200 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl w-full space-y-8">
        <div className="bg-white rounded-3xl shadow-2xl overflow-hidden lg:flex">
          {/* Left Side - Image Section */}
          <div className="lg:w-1/2 relative">
            <img src={StudentImage} className="h-full w-full object-cover" alt="Student Registration" />
            <div className="absolute inset-0 bg-gradient-to-t from-indigo-900 to-transparent opacity-70"></div>
            <div className="absolute bottom-0 left-0 p-8 text-white">
              <h2 className="text-4xl font-bold mb-2">St. Arnold School</h2>
              <p className="text-xl">Empowering minds, shaping futures</p>
            </div>
          </div>

          {/* Right Side - Form Section */}
          <div className="p-5 lg:w-1/2 bg-white">
            <div className="text-center mb-4">
              <h2 className="text-3xl font-bold text-gray-800 mb-2">Register New User</h2>
              <p className="text-indigo-600 font-semibold">Date: {formatDate(selectedToDate)}</p>
            </div>

            {/* Form */}
            <form className="space-y-3" onSubmit={onSubmit}>
              <div>
                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="userId">
                  User Id
                </label>
                <input
                  id="userId"
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter user id"
                  onChange={(e) => setUserId(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="schoolId">
                  School Id
                </label>
                <input
                  id="schoolId"
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter school id"
                  onChange={(e) => setSchoolId(e.target.value)}
                  value={schoolId}
                  required
                />
              </div>

              <div>
                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="userType">
                  User Type
                </label>
                <select
                  id="userType"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  onChange={(e) => setUserType(e.target.value)}
                  required
                >
                  <option hidden disabled selected value="">
                    Select User Type
                  </option>
                  <option value="Parent">Parent</option>
                  <option value="Student">Student</option>
                  <option value="Teacher">Teacher</option>
                  <option value="Admin">Admin</option>
                </select>
              </div>

              <div>
                <label className="block text-gray-700 text-sm font-semibold mb-2" htmlFor="year">
                  Year
                </label>
                <select
                  id="year"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  onChange={(e) => setYear(e.target.value)}
                  required
                >
                  <option hidden disabled selected value="">
                    Select Year
                  </option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                </select>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="terms"
                  checked={termsAccepted}
                  onChange={handleCheckboxChange}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <label htmlFor="terms" className="ml-2 text-sm font-medium text-gray-700">
                  I accept the terms and conditions
                </label>
              </div>

              <button
                type="submit"
                className={`w-full py-3 px-4 bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-semibold rounded-lg shadow-md hover:from-blue-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition-all duration-300 ease-in-out ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Registering..." : "Register"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewRegistration;
