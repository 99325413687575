import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getAdminScrollList } from "../API/getAdminScrollNoticeList";
import { GetAppSlideFolder } from "../API/GetAppSlideFolder";
import { getUserDataFromLocalByKey } from "../API/getStudentInfoByKeyLocal";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import NoticeListystem from "../components/NotificationBar";
import Sidebar from "../components/Sidebar";
import ParallaxSwiper from "../components/TripleSlider";
import { useSidebar } from "../context/SidebarContext";

const Home = () => {
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const [userDetails, setUserDetails] = useState(null);
  const [sliderImages, setSliderImages] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [activeType, setActiveType] = useState("1");
  const [noticeList, setNoticeList] = useState([]);

  const location = useLocation();

  const { customId } = useParams();

  const fetchSliderImage = async () => {
    try {
      if (userDetails.length > 0) {
        const key = "webResourceURL";

        const url = await getUserDataFromLocalByKey(
          userDetails[0]?.userId,
          userDetails[0]?.schoolId,
          userDetails[0]?.userType,
          userDetails[0]?.year,
          key
        );
        setImageUrl(url);
        const response = await GetAppSlideFolder(
          userDetails[0]?.userId,
          userDetails[0]?.schoolId,
          userDetails[0]?.userType,
          userDetails[0]?.year
        );

        if (response[0].ErrorMessage === null) {
          setSliderImages(JSON.parse(response[0].JSONData1));
        }

        const result = await getAdminScrollList(
          userDetails[0]?.userId,
          userDetails[0]?.schoolId,
          userDetails[0]?.userType,
          userDetails[0]?.year,
          activeType
        );
        if (result[0]?.ActionType === 1) {
          const noticeList = JSON.parse(result[0]?.JSONData1);

          setNoticeList(noticeList);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    const users = JSON.parse(localStorage.getItem("registeredUsers")) || [];

    const decodedData = JSON.parse(atob(customId));

    if (customId) {
      const decodedData = JSON.parse(atob(customId));
      localStorage.setItem(`${customId}`, JSON.stringify(decodedData));
    }

    let matchedItem = [];

    users.forEach((innerArray) => {
      const [userData] = innerArray;

      if (
        userData.userId === decodedData.userId &&
        userData.userType === decodedData.userType &&
        userData.schoolId === decodedData.schoolId &&
        userData.year === decodedData.year
      ) {
        matchedItem.push(userData);
      }
    });

    setUserDetails(matchedItem);

  }, [customId]);

  useEffect(() => {
    fetchSliderImage();
  }, [userDetails]);

  return (
    <>
      {userDetails && (
        <>
          {<Navbar data={userDetails} toggleSidebar={toggleSidebar} />}

          <div className="flex">
            {<Sidebar data={userDetails} />}
            <div
              className={`w-full absolute ${
                isSidebarOpen ? "lg:w-[75%] xl:w-[80%]" : "w-full"
              }  right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
            >
              {userDetails[0].userType !== "Admin" && (
                <div className="mb-6 md:pl-10">
                  <NoticeListystem noticeList={noticeList} />
                </div>
              )}
              <div className="md:pl-10">
                {userDetails[0].userType !== "Admin" && (
                  <ParallaxSwiper sliderImages={sliderImages} imageUrl={imageUrl} key={location.pathname} />
                )}
              </div>
              <Content data={userDetails} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
