import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
// import useSidebar from '../../hooks/useSidebar';
import { useSidebar } from "../../context/SidebarContext";
import CTAbsantees from "./CTAbsantees";
import CTAttendance from "./CTAttendance";
import CTClassTimeTable from "./CTClassTimeTable";
import CTClassWisePoll from "./CTClassWisePoll";
import CTComBWTeachers from "./CTComBWTeachers";
import CTGallery from "./CTGallery";
import CTHomework from "./CTHomework";
import CTMarkEntry from "./CTMarkEntry";
import CTNoticeBoard from "./CTNoticeBoard";
import CTNotification from "./CTNotification";
import CTPolls from "./CTPolls";
import CTProfile from "./CTProfile";
import CTReplyToParent from "./CTReplyToParent";
import CTStudentDetails from "./CTStudentDetails";
import CTSubjectList from "./CTSubjectList";
import CTTeachersList from "./CTTeachersList";
import CTTeacherTimeTable from "./CTTeacherTimeTable";
import CTTodaySchedule from "./CTTodaySchedule";
import SubjectWiseMarkEntry from "./SubjectWiseMarkEntry";

const CustomClassTeacherPage = () => {
  const { customId, keyName } = useParams();
  const [userDetails, setUserDetails] = useState(null);

  const [data, setData] = useState(true);
  const { isSidebarOpen,  toggleSidebar } = useSidebar();

  useEffect(() => {
    if (customId) {
      const decodedData = JSON.parse(atob(customId));
      setUserDetails(decodedData);
    }
  }, [customId, keyName]);

  return (
    <>
      {data && <Navbar data={userDetails} toggleSidebar={toggleSidebar} />}

      <div className="flex">
        {data && <Sidebar data={userDetails} />}

        <div
          className={`w-full absolute ${
            isSidebarOpen ? "lg:w-4/6 xl:w-9/12" : "w-full"
          } sm:w-11/12 right-0 top-16 mx-auto   p-4 md:p-6 lg:p-8 xl:p-10`}
        >
          {keyName === "CTNotification" && <CTNotification />}
          {keyName === "CTAttendance" && <CTAttendance />}
          {keyName === "CTHomeWork" && <CTHomework />}
          {keyName === "CTGallery" && <CTGallery />}
          {keyName === "CTCommunication" && <CTReplyToParent />}
          {keyName === "CTNoticeBoard" && <CTNoticeBoard />}
          {keyName === "CTMyProfile" && <CTProfile />}
          {keyName === "CTTimeTable" && <CTClassTimeTable />}
          {keyName === "CTMarkEntry" && <CTMarkEntry />}
          {keyName === "CTCBWTeachers" && <CTComBWTeachers />}
          {keyName === "CTTTimeTable" && <CTTeacherTimeTable />}
          {keyName === "CTSubjectList" && <CTSubjectList />}
          {keyName === "CTTodaySch" && <CTTodaySchedule />}
          {keyName === "CTStudents" && <CTStudentDetails />}
          {keyName === "CTCPoll" && <CTClassWisePoll />}
          {keyName === "CTTeachers" && <CTTeachersList />}

          {keyName === "CTAbsantees" && <CTAbsantees />}
          {keyName === "SubjectMarkEntry" && <SubjectWiseMarkEntry />}
          {keyName === "CTPoll" && <CTPolls />}

          {/* CTTimeTable
          CTTTimeTable
          CTTodaySch
          CTPoll
          CTCPoll
          CTStudents */}
        </div>
      </div>
    </>
  );
};

export default CustomClassTeacherPage;
