import axios from "axios";

export const GetClassWiseTermList = async (empCode, year, clss, sectn, subjt) => {
  try {
    const url = process.env.REACT_APP_ERP_CBSE_EXAM_PAGE;
    const formData = new FormData();
    formData.append("title", "GetClassWiseTermList");
    formData.append("description", "Checking");
    formData.append("ReqEmpCode", empCode);
    formData.append("ReqAcaStart", year);
    formData.append("ReqClName", clss);
    formData.append("ReqSecName", sectn);
    formData.append("ReqSubject", subjt);

    let userdata = [];
    const result = await axios.post(url, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    return userdata;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
