import axios from "axios";

export const GetTeacherWiseSubjectList = async (empCode, year) => {
  try {
    const url = process.env.REACT_APP_ERP_CBSE_EXAM_PAGE;
    const formData = new FormData();
    formData.append("title", "GetTeacherWiseSubjectList");
    formData.append("description", "Checking");
    formData.append("ReqEmpCode", empCode);
    formData.append("ReqAcaStart", year);

    let userdata = [];

    const result = await axios.post(url, formData);
    userdata = JSON.parse(result.data.substring(0, result.data.indexOf("||JasonEnd", 0)));
    const jsonData1 = JSON.parse(userdata[0].JSONData1);

    return jsonData1;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
