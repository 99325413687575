import axios from "axios";

export const adminSyncClass = async (year, preUrl) => {
  try {
    const postData = {
      year: year,
    };

    const result = await axios.post(`${preUrl}handle_class_master`, postData, {
      headers: {
        "Content-Type": "application/json", // Specify content type as JSON
      },
    });

    return result;
  } catch (error) {
    console.error("An error occurred:", error);
  }
};
